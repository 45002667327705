.head-cont{
  width: 90%;
  margin: 10px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 2px 5px 8px 2px rgba(0,0,0,0.2);
}
.form{
  margin: 60px;
  border-radius: 30px;
  border: none;
  box-shadow: 2px 5px 8px 2px rgba(0,0,0,0.2);
}
#UMFB-Logo{
  width: 300px;
  margin: 20px;
}
h2{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.note{
  text-align: left;
  margin-left: 20px;
}
.red-asteriks{
  color: red;
}
.form-body{
  width: 90%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  padding: 30px;
  border-radius: 10px;
}
.progressgraph{
  display: flex;
  justify-content: center;
}
.circle-active{
  display: block;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: 2px solid maroon;
}
.circle-completed{
  display: block;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  line-height: 50px;
  color: white;
  border: 2px solid maroon;
  background-color: maroon;
}
.circle-inactive{
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: 2px solid darkgray;
}
.inner-circle{
  height: 10px;
  width: 10px;
  border-radius: 10px;
  margin: 20px;
  background-color: maroon;
}
.edge{
  display: block;
  height: 3px;
  width: 40%;
  margin: 25px 0px;
  background-color: darkgray;
}
.edge-active{
  display: block;
  height: 3px;
  width: 40%;
  margin: 25px 0px;
  background-color: maroon;
}
.form-wrap{
  margin: 0px 20px;
}
.mini-head{
  padding-top: 20px;
  text-align: left;
  margin-left: 20px;
  font-size: 20px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 700;
  color: maroon;
}
label{
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 700;
}

/* for both page sections */
.page-section{
  margin: 20px 0px;
}
/* container wrapping input/select and label */
.item-container{
  display: inline-block;
  margin: 15px;
  text-align: left;
}
/* all input */
.entries{
  width: 290px;
  height: 40px;
  padding: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 16px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: rgb(240, 239, 239);
}
/* all select */
.list{
  width: 310px;
  padding: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 16px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: rgb(240, 239, 239);
}
.long{
  width: 970px;
}
.mid{
  width: 650px;
}
option{
  padding: 10px;
  height: 10px;
  margin: 20px;
  line-height: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.img-container{
  display: flex;
  padding: 0px 40px;
}
.image{
  width: 280px;
  padding: 10px 10px;
  margin: 0px 20px;
  font-size: 15px;
  text-align: left;
}
.no-image{
  height: 200px;
}
.picture-box{
  display: inline-block;
  height: 200px;
  color: lightgray;
  line-height: 200px;
  text-align: center;
  border: 0.5px solid gray;
  font-weight: 600;
  font-size: 15px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.select-image{
  display: inline-block;
  color: black;
  font-size: 15px;
}
/* section head */
.section-head{
  width: 30%;
  padding: 15px 20px;
  border-radius: 25px ;
  height: 20px;
  background-color: maroon;
  color: white;
  font-weight: 400;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 20px;
  text-align: center;
}
.section-head-hr{
  display: flex;
  justify-content: space-around;
}
.hr-cont{
  padding: 15px 0px;
  width: 30%;
}
.button-cont{
  display: flex;
  justify-content: right;
  width: 92%;
}
.btn{
  background-color: maroon;
  color: white;
  font-size: 18px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  padding: 10px 40px;
  margin: 10px;
  border-radius: 3px;
  border: none;
}
.btn:hover{
  background-color: #b1d035;
}
.file-input{
  width: 120px;
  height: 20px;
  font-size: 11px;
}
.requirements{
  margin: 0px 20px;
 font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.declaration{
  width: 60%;
}
.arrow{
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  color: gray;
  cursor: pointer;
}
.arrow:hover{
  color: green;
}
.parastatals-cont{
  margin: 100px;
}
.parastatal-cont{
  display: inline-block;
  width: 250px;
  border-radius: 10px;
  margin: 10px;
  padding: 20px;
}
.p-logo{
  width: 200px;
}
.btn-landing{
  font-size: 15px;
  width: 250px;
  background-color: maroon;
  color: white;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  padding: 15px 10px;
  margin: 15px;
  border-radius: 3px;
  border: none;
}
.btn-landing:hover{
  background-color: #b1d035;
  color: black;
  cursor: pointer;
}